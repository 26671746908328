<template>
    <div>
        <hr>
        <b-card-text>Automatic Solution Advice</b-card-text>
        <div v-for="item in analysisData" :key="item.id">
            <b-alert variant="danger" show>
                <div class="alert-body">
                    <div v-html="item.description"></div>
                </div>
            </b-alert>

            <b-alert variant="success" show>
                <h5 class="alert-heading">
                    Resolution
                </h5>
                <div class="alert-body text-dark">
                    <div v-html="item.solution"></div>
                </div>
            </b-alert>
        </div>
    </div>
</template>
<script>
import {
	BCardText,
    BAlert,
} from 'bootstrap-vue'
export default{
     components: {
       BCardText,
       BAlert
    },
    props:{
        analysisData:{}
    }

}
</script>
