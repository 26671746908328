<template>
    <div>
        <b-card v-if="buildDetailData && !isLoading" class="buildDataOverflow">
            <div class="d-flex align-items-center mobile-pos-header">
                <div class="d-flex align-items-center">
                    <div class="icon mr-2">
                        <b-avatar rounded="sm" size="48" :src="backend_url+buildDetailData.icon"
                                  :text="avatarText(buildDetailData.product_name)"
                                  :variant="`light-secondary`"/>

                    </div>
                    <div class="info auto-white-overflow" style="white-space: nowrap">
                        <h3>
                            <b-media vertical-align="center">
                                <span>{{ buildDetailData.product_name }} </span>
                                <b-badge variant="light-success" v-if="buildDetailData.debug == 0">Release</b-badge>
                                <b-badge variant="light-warning" v-else>Debug</b-badge>
                                <template v-if="buildDetailData.extraArguments">
                                    <b-badge class="ml-25" variant="light-secondary" v-if="getCustomName(buildDetailData.extraArguments)">{{getCustomName(buildDetailData.extraArguments)}}</b-badge>
                                </template>
                            </b-media>
                        </h3>
                        <h6 class="d-flex align-items-center">
                            <social-icons :socialType="buildDetailData.os" :size="'16'"></social-icons>
                            <span v-if="$can('read', 'buildTeamAccess') && buildDetailData.team"
                                  style="margin-left:6px">
                                Team: {{ buildDetailData.team.name }},
                            </span>
                            Version: {{ buildDetailData.bundle_version }},
                            Server: {{ buildDetailData.build_server_name }},
                            Commit:
                            <b-badge target="_blank"
                                     :href="'https://bitbucket.org/gameguru/'+buildDetailData.repo+'/commits/'+buildDetailData.commit"
                                     variant="light-secondary">
                                <feather-icon icon="LinkIcon" class="mr-25"/>
                                <span>{{ buildDetailData.commit.substring(0, 6) }}</span>
                            </b-badge>
                        </h6>
                    </div>
                </div>
            </div>
            <build-app-error-analyses v-if="buildDetailData.status =='failed' && buildDetailData.error_analyses"
                                      :analysisData="buildDetailData.error_analyses"></build-app-error-analyses>
            <hr>
            <b-card-text v-if="buildDetailResultData.length>0">Build Steps</b-card-text>
            <build-app-detail-timeline id="timeOverflow" v-if="buildDetailResultData.length>0">
                <build-app-detail-timeline-item v-for="step in buildDetailResultData" :key="step.key"
                                                :variant="fintTimeLineColor(step)" class="mr-2 ml-1">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                        <h6>{{ step.name }}</h6>
                        <small class="text-muted">{{ step.duration.toFixed(2) }} sec</small>
                    </div>
                    <div class="messages">
                        <div role="alert" v-for="message in step.messages" class="alert" :key="message.key">
                            <div v-if="message.content">
                                <b-alert variant="danger" show v-if="message.type == 0 || message.type== 4"
                                         class="pre-overflow-x">
                                    <div class="alert-body">
                                        <div v-html="message.content"></div>
                                    </div>
                                </b-alert>
                                <b-alert variant="warning" show v-else-if="message.type == 2" class="pre-overflow-x">
                                    <div class="alert-body">
                                        <div v-html="message.content"></div>
                                    </div>
                                </b-alert>
                                <b-alert variant="secondary" show v-else class="pre-overflow-x">
                                    <div class="alert-body">
                                        <div v-html="message.content"></div>
                                    </div>
                                </b-alert>
                            </div>
                        </div>
                    </div>
                    <div class="substeps mt-2" v-if="step.steps.length>0">
                        <build-app-detail-timeline>
                            <build-app-detail-timeline-item-child v-for="stepchild in step.steps" :key="stepchild.key"
                                                                  :steps="stepchild"></build-app-detail-timeline-item-child>
                        </build-app-detail-timeline>
                    </div>
                </build-app-detail-timeline-item>
            </build-app-detail-timeline>
            <div v-else>
                <div class="w-100 text-center mt-4">
                    <h2 class="mb-1"> Log not found 🚀 </h2>
                </div>
            </div>
        </b-card>
        <b-card v-if="isLoading" class="mt-2">
            <div class="text-center my-2">
                <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </b-card>
    </div>
</template>

<script>
import {BCard, BCardText, BBadge, BImg, BAlert, BSpinner, BLink, BMedia, BAvatar} from 'bootstrap-vue'
import BuildAppDetailTimeline from '@/components/BuildAppDetailTimeline.vue'
import BuildAppDetailTimelineItem from '@/components/BuildAppDetailTimelineItem.vue'
import BuildAppDetailTimelineItemChild from '@/components/BuildAppDetailTimelineItemChild.vue'
import useBuildManagerApi from '@/composables/useBuildManagerApi'
import router from '@/router'
import BuildAppErrorAnalyses from '@/components/BuildAppErrorAnalyses.vue'
import {avatarText} from "@core/utils/filter";
import SocialIcons from '@/components/SocialIcons.vue'

export default {
    components: {
        BuildActionItem: () => import('@/components/BuildAppActionItem.vue'),
        BSpinner,
        BCard,
        BCardText,
        BBadge,
        BImg,
        BMedia,
        BAvatar,
        BuildAppDetailTimeline,
        BuildAppDetailTimelineItem,
        'build-app-detail-timeline-item-child': BuildAppDetailTimelineItemChild,
        BAlert,
        BLink,
        BuildAppErrorAnalyses,
        SocialIcons
    },
    setup() {
        const {
            fetchBuild,
            buildDetailData,
            buildDetailResultData,
            isLoading,
        } = useBuildManagerApi()
        return {
            fetchBuild,
            buildDetailData,
            buildDetailResultData,
            isLoading,
            avatarText
        }
    },
    mounted() {
        this.fetchBuild(router.currentRoute.params.build)
    },
    data() {
        return {
            backend_url: process.env.VUE_APP_BACKEND_URL
        }
    },
    methods: {
        fintTimeLineColor(step) {
            let timelineClass = 'success'
            const messages = step.messages;
            if (messages) {
                messages.map((message) => {
                    if (message.type === 0 || message.type === 4) {
                        timelineClass = 'danger'
                    }
                    if (message.type === 2) {
                        if (timelineClass === 'success') {
                            timelineClass = 'warning'
                        }
                    }
                })
            }
            return timelineClass
        },
        getCustomName (text){
            const match = /-customBuild\s+(\w+)/.exec(text);
            return match ? match[1] : false;
        }
    }
}
</script>

<style>
.pre-overflow-x {
    white-space: pre !important;
    overflow-x: auto !important;
}
</style>
