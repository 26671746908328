<template>
	<app-timeline-item :variant="fintTimeLineColor(steps)">
		<div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
			<h6>{{steps.name}}</h6>
			<small class="text-muted">{{steps.duration.toFixed(2)}} sec</small>
		</div>
		<div class="messages" v-if="steps.messages.length>0">
			<div class="alert" role="alert" v-for="message in steps.messages" :key="message.key">
				<div v-if="message.content">
					<b-alert variant="danger" show v-if="message.type == 0 || message.type== 4" class="pre-overflow-x">
						<div class="alert-body">
							<div v-html="message.content"></div>
						</div>
					</b-alert>

					<b-alert variant="warning" show v-else-if="message.type == 2" class="pre-overflow-x">
						<div class="alert-body">
							<div v-html="message.content"></div>
						</div>
					</b-alert>

					<b-alert variant="secondary" show v-else class="pre-overflow-x">
						<div class="alert-body">
							<div v-html="message.content"></div>
						</div>
					</b-alert>
				</div>
			</div>
		</div>
		<div class="substeps mt-2" v-if="steps.steps.length>0">
			<app-timeline>
				<log-timeline-item v-for="stepchild in steps.steps" :key="stepchild.key" :steps="stepchild">
				</log-timeline-item>
			</app-timeline>
		</div>
	</app-timeline-item>
</template>

<script>
import { BCard, BCardText,BBadge,BImg,BAlert } from 'bootstrap-vue'
import AppTimeline from '@/components/BuildAppDetailTimeline.vue'
import AppTimelineItem from '@/components/BuildAppDetailTimelineItem.vue'
export default {
	name: 'LogTimeLineItems',
	components: {
		BCard,
		BCardText,
		BBadge,
		BImg,
		LogTimelineItem: () => import('@/components/BuildAppDetailTimelineItemChild.vue'),
		AppTimeline,
		AppTimelineItem,
		BAlert
	},
	props: ['steps'],
	methods: {
		fintTimeLineColor(step) {
			const messages = step.messages;
			if (messages) {
				let timelineClass = 'success'
				messages.map((message) => {
					if (message.type === 0 || message.type === 4) {
						timelineClass = 'danger'
					} else if (message.type === 2) {
						if (timelineClass === 'success') {
							timelineClass = 'warning'
						}
					}
				})
				return timelineClass

			}
		}
	}
}
</script>
<style lang="scss" scoped>
.pre-overflow-x{
	white-space: pre!important;
  	overflow-x:auto!important;
}
</style>
