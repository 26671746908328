import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default function useBuildManagerApi() {
  // Use toast
  const toast = useToast()

  const refBuildListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'Build', sortable: true },
    { key: 'OS', sortable: true },
    { key: 'product_name', sortable: false, label: 'Project' },
    { key: 'user', sortable: false, label: 'User' },
    { key: 'Commit', sortable: true },
    { key: 'debug', sortable: true, label: "Type" },
    { key: 'editor_version', sortable: false, label: 'Unity Version' },
    { label: 'Version', sortable: false, key: 'bundle_version' },
    { key: 'Status', sortable: true },
    { label: 'Time', sortable: true, key: 'created_at' },
    { key: 'actions', style: 'width:10px' },
  ]
  const osOptions = [
    'ANDROID', 'IOS',
  ]

  const statusOptions = [
    { label: 'Waiting', value: 'waiting' },
    { label: 'In Progress', value: 'inprogress' },
    { label: 'Failed', value: 'failed' },
    { label: 'Succeeded', value: 'succeeded' },
  ]
  const perPage = ref(localStorage.getItem('buildPerPage') ?? 10)
  const totalBuilds = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 15, 20, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const osFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const buildDetailData = ref(null)
  const buildDetailResultData = ref(null)
  const isLoading = ref(true)
  const teamFilter = ref(null)
  const teamList = ref(null)
  const developerList = ref(null)
  const developerFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refBuildListTable.value ? refBuildListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBuilds.value,
    }
  })

  const refetchData = () => {
    localStorage.setItem('buildPerPage', perPage.value)
    refBuildListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, osFilter, planFilter, statusFilter, teamFilter, developerFilter], () => {
    refetchData()
  })

  watch([teamFilter], () => {
    developerFilter.value = null
    fetchDevelopers()
  })

  const fetchBuilds = (ctx, callback) => {
    let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
    store
      .dispatch('buildStore/fetchBuilds', {
        sort: sort,
        page: currentPage.value,
        perPage: perPage.value,
        q: searchQuery.value,
        os: osFilter.value,
        status: statusFilter.value,
        pagination: true,
        team: teamFilter.value,
        developer: developerFilter.value
      })
      .then(response => {
        const { data, total } = response.data
        callback(data)
        totalBuilds.value = response.data.pagination.total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Oops! Something went wrong!',
          },
        })
      })
  }

  const fetchBuild = (id) => {
    store.dispatch('buildStore/fetchBuild', { id: id })
      .then(response => {
        const { data } = response.data
        buildDetailData.value = data
        let steps = [];
        const logs = response.data.data.logs;
        for (let i = 0; i < logs.length; i++) {
          let step = logs[i];
          let stepContents = {};
          stepContents.name = step.type;
          if (step.type === "unity_logs") {
            try{
              stepContents.steps = builUnitySteps(JSON.parse(step.details).BuildReport.m_BuildSteps);
              stepContents.duration = 0;
            }catch(e){
              stepContents.messages = [{ type: step.success, content: escapeHtml(step.details) }];
              stepContents.duration = 0;
              stepContents.steps = [];
            }
          
          }
          else if (step.type === "fastlane") {
            stepContents.steps = buildFastlaneSteps(step.details);
            stepContents.duration = 0;
          }
          else {
            stepContents.messages = [{ type: step.success, content: escapeHtml(step.details) }];
            stepContents.duration = 0;
            stepContents.steps = [];
          }
          steps.push(stepContents);
        }
        buildDetailResultData.value = steps

        function buildFastlaneSteps(xml) {
          let parser = new DOMParser();
          let xmlDoc = parser.parseFromString(xml, "text/xml");
          let lanesteps = xmlDoc.getElementsByTagName("testcase")
          let steps = [];
          for (let i = 0; i < lanesteps.length; i++) {
            let lanestep = lanesteps[i];
            let stepContents = {};
            stepContents.name = lanestep.getAttribute("name");
            stepContents.duration = lanestep.getAttribute("time") * 1
            stepContents.steps = [];
            stepContents.messages = [];
            if (lanestep.childElementCount > 0 && lanestep.children[0].tagName === "failure") {
              stepContents.messages.push({
                type: 0,
                content: lanestep.children[0].getAttribute("message")
              })
            }
            steps.push(stepContents);
          }
          return steps;
        }

        function builUnitySteps(steps) {
          let buildSteps = steps;
          let outer = { steps: [] };
          let stepDepthMap = {};
          stepDepthMap[0] = outer;
          for (let i = 0; i < buildSteps.length; i++) {
            let step = buildSteps[i];
            let stepContents = {};
            stepContents.name = step.stepName;
            stepContents.messages = step.messages;
            stepContents.duration = step.durationTicks / 100000;
            stepContents.steps = [];
            stepDepthMap[step.depth].steps.push(stepContents);
            stepDepthMap[step.depth + 1] = stepContents;
          }
          return outer.steps;
        }

        function escapeHtml(str) {
          return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
        }
        isLoading.value = false

      }).catch(error => {
        buildDetailData.value = undefined
        buildDetailResultData.value = undefined
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Oops! Something went wrong!',
          },
        })
        router.push('/');


      })

  }

  const downloadBuild = (id) => {
    store
      .dispatch('buildStore/downloadBuild', {
        id: id
      })
      .then(response => {
        window.open(response.data.data)
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Not found.',
            icon: 'AlertTriangleIcon',
            variant: 'info',
            text: 'If you think there is an error, please contact the admin.',
          },
        })
      })
  }
  const confirmRestartBuild = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to restart the build process?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, restart build!',
      customClass: {
        confirmButton: 'btn btn-primary mr-1',
        cancelButton: 'btn btn-outline-primary ms-1'
      },
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        store.dispatch('buildStore/restartBuild', { id: id })
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Successfully restart builded!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            refetchData()
          })
          .catch(error => {
            console.log(error)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Oops! Something went wrong!',
              },
            })
          })
      }
    })
  }
  const deleteBuild = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to cancel the process?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, cancel',
      customClass: {
        confirmButton: 'btn btn-primary mr-1',
        cancelButton: 'btn btn-outline-primary ms-1'
      },
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        store.dispatch('buildStore/deleteBuild', { id: id })
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Successfully deleted!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            refetchData()
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Oops! Something went wrong!',
              },
            })
          })
      }
    })
  }


  const createQATask = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to send QA testing?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, send!',
      customClass: {
        confirmButton: 'btn btn-primary mr-1',
        cancelButton: 'btn btn-outline-primary ms-1'
      },
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        Swal.fire({
          title: 'Please Wait !',
          html: 'A task is being created for QA.',// add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        });
        store.dispatch('buildStore/createQA', {
          id: id,
        })
          .then(response => {
            if (response.status == 200) {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Successfully QA sended!',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            } else {
              toast({ component: ToastificationContent, props: { title: 'Error', icon: 'AlertTriangleIcon', variant: 'danger', text: 'Oops! Something went wrong!', }, })
            }
            Swal.close();
            refetchData()
          })
          .catch(error => {
            Swal.close();
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Oops! Something went wrong!',
              },
            })
          })
      }
    })
  }

  // ------------------------------------------------
  // Get Team List
  // ------------------------------------------------
  const fetchTeamList = (ctx, callback) => {
    store
      .dispatch('teamStore/fetchTeams')
      .then(response => {
        const { data } = response.data
        teamList.value = data.map(item => ({ value: item.id, label: item.name }))
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Oops! Something went wrong!',
          },
        })
      })
  }
  // ------------------------------------------------
  // Get Developer List
  // ------------------------------------------------
  const fetchDevelopers = (team_id) => {
    store
      .dispatch('buildStore/fetchDevelopers', {
        team: teamFilter.value ?? team_id
      })
      .then(response => {
        const { data } = response.data
        developerList.value = data.map(item => ({
          id: item.id,
          name: item.name,
          image: item.image
        }))
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Oops! Something went wrong!',
          },
        })
      })
  }

  return {
    fetchBuilds,
    downloadBuild,
    tableColumns,
    perPage,
    currentPage,
    totalBuilds,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBuildListTable,
    refetchData,
    osOptions,
    osFilter,
    planFilter,
    statusFilter,
    statusOptions,
    fetchBuild,
    buildDetailData,
    buildDetailResultData,
    isLoading,
    confirmRestartBuild,
    deleteBuild,
    createQATask,

    teamFilter,
    teamList,
    fetchTeamList,
    fetchDevelopers,
    developerList,
    developerFilter
  }
}
